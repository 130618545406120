import React from "react";

import People from "./templates/people.jsx";

import "../css/customlanding.css";

const AboutUsLandingPage = ({ pageContext }) => {
  return (
    <div className="custom-landing-block">
      <People
        pageContext={pageContext}
        isHomeBannerVisible={false}
        isCarCarouselVisible={false}
        isBookingFormsVisible={false}
        isBookingModelVisible={false}
        isAboutPageVisible
      />
    </div>
  );
};

export default AboutUsLandingPage;

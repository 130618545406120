import React, { Component } from "react"

export class Address extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick = () => {
    if (window && window.gtag) {
      window.gtag("event", "get_direction_clicked", {
        event_label: "Get Direction Clicked",
      });
    }
    window.open(this.props.gmbLink, "_blank")
  }
  render() {
    return (
      <div className="address-section">
        <b>{this.props.dealer}</b>
        <p>{this.props.address}</p>

        <br />

        <b>Opening Hours</b>
        <p>10:00 AM - 07:00 PM</p>

        <br/>

        <button
          className="btn bg-hyundaiblue btn-direction"
          id="btn-direction"
          type="button"
          onClick={this.handleClick}
        >
          Get Direction
        </button>
      </div>
    )
  }
}

export default Address

import React, { Component } from "react"
import logo from "../images/logo.svg"
import mobileLogo from "../images/titleLogo-min.png";
import "../css/header.css"
import "../css/globalRules.css"


export class Header extends Component {

  render() {
    const { isNormalVisit } = this.props;
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid mobileViewContainer">
            <a className="navbar-brand" href="/">
              <div className="desktopView">
                <img src={logo} height="40px" width="40%" />
              </div>
              <div class="mobileView">
                <img src={mobileLogo} height="40px" width="40%" />

                <p className="mobileDealerName">{this.props.dealer}</p>
              </div>
            </a>
            <div className="ms-auto desktopView">
              <p>{this.props.dealer}</p>
              {isNormalVisit ?
                <span>
                  <i class="fa fa-phone" aria-hidden="true">&nbsp;</i>
                  {this.props.contact}
                </span>
              : <span className="desktopview hiddencallview" />}
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

export default Header

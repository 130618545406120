import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types";

import "../css/homeBanner.css"
import "../css/globalRules.css"

const BannerImage = (props) => {
  return (
    <StaticQuery query={graphql`
          query {

            aura: file(relativePath: { eq: "desktop-banners/aura-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            mobAura: file(relativePath: { eq: "mobile-banners/aura-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            auraCng: file(relativePath: { eq: "desktop-banners/aura-cng-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            mobAuraCng: file(relativePath: { eq: "mobile-banners/aura-cng-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            creta: file(relativePath: { eq: "desktop-banners/creta-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobCreta: file(relativePath: { eq: "mobile-banners/creta-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            cretaNLine: file(relativePath: { eq: "desktop-banners/creta-n-line-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobCretaNLine: file(relativePath: { eq: "mobile-banners/creta-n-line-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            i20: file(relativePath: { eq: "desktop-banners/i20-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobI20: file(relativePath: { eq: "mobile-banners/i20-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            iMT: file(relativePath: { eq: "desktop-banners/i20-imt-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobIMT: file(relativePath: { eq: "mobile-banners/i20-imt-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            i20NLine: file(relativePath: { eq: "desktop-banners/i20-n-line-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobi20NLine: file(relativePath: { eq: "mobile-banners/i20-n-line-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            iNLineiMTBanner: file(relativePath: { eq: "desktop-banners/i20-n-line-imt-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobileiNLineiMTBanner: file(relativePath: { eq: "mobile-banners/i20-n-line-imt-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            cretaelectric: file(relativePath: { eq: "desktop-banners/creta-ev-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobcretaelectric: file(relativePath: { eq: "mobile-banners/creta-ev-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            nios: file(relativePath: { eq: "desktop-banners/nios-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobNios: file(relativePath: { eq: "mobile-banners/nios-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            niosCng: file(relativePath: { eq: "desktop-banners/nios-cng-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobNiosCng: file(relativePath: { eq: "mobile-banners/nios-cng-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tucson: file(relativePath: { eq: "desktop-banners/tucson-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobTucson: file(relativePath: { eq: "mobile-banners/tucson-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            allNewVerna: file(relativePath: { eq: "desktop-banners/new-verna-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobAllNewVerna: file(relativePath: { eq: "mobile-banners/new-verna-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            venue: file(relativePath: { eq: "desktop-banners/venue-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobVenue: file(relativePath: { eq: "mobile-banners/venue-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            venueNLine: file(relativePath: { eq: "desktop-banners/venue-n-line-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobVenueNLine: file(relativePath: { eq: "mobile-banners/venue-n-line-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            venueiMT: file(relativePath: { eq: "desktop-banners/venue-imt-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobVenueiMT: file(relativePath: { eq: "mobile-banners/venue-imt-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alcazar: file(relativePath: { eq: "desktop-banners/alcazar-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobAlcazar: file(relativePath: { eq: "mobile-banners/alcazar-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            exter: file(relativePath: { eq: "desktop-banners/exter-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobExter: file(relativePath: { eq: "mobile-banners/exter-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            exterCng: file(relativePath: { eq: "desktop-banners/exter-cng-desktop.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            mobExterCng: file(relativePath: { eq: "mobile-banners/exter-cng-mobile.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
      `}

      render={data => {

        const imagesObject = {
          "auraBanner": [
            {
              ...data.mobAura.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.aura.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }

          ],
          "auraCngBanner": [
            {
              ...data.mobAuraCng.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.auraCng.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],

          "cretaBanner": [
            {
              ...data.mobCreta.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.creta.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],

          "cretaNLineBanner": [
            {
              ...data.mobCretaNLine.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.cretaNLine.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],

          "niosBanner": [
            {
              ...data.mobNios.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.nios.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],

          "i20Banner": [
            {
              ...data.mobI20.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.i20.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],

          "iMTBanner": [
            {
              ...data.mobIMT.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.iMT.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],

          "iNLineBanner": [
            {
              ...data.mobi20NLine.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.i20NLine.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],

          "iNLineiMTBanner": [
            {
              ...data.mobileiNLineiMTBanner.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.iNLineiMTBanner.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],

          "cretaelectricBanner": [
            {
              ...data.mobcretaelectric.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.cretaelectric.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],

          "niosCngBanner": [
            {
              ...data.mobNiosCng.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.niosCng.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],

          "tucsonBanner": [
            {
              ...data.mobTucson.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.tucson.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],

          "allNewVernaBanner": [
            {
              ...data.mobAllNewVerna.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.allNewVerna.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],

          "venueBanner": [
            {
              ...data.mobVenue.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.venue.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],
          "venueNLineBanner": [
            {
              ...data.mobVenueNLine.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.venueNLine.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],
          "venueiMTBanner": [
            {
              ...data.mobVenueiMT.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.venueiMT.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],
          "alcazarBanner": [
            {
              ...data.mobAlcazar.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.alcazar.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],
          "exterBanner": [
            {
              ...data.mobExter.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.exter.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],
          "exterCngBanner": [
            {
              ...data.mobExterCng.childImageSharp.fluid,
              //  aspectRatio: 5/3
            },
            {
              ...data.exterCng.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            }
          ],
        }

        return (
          <Img fluid={
            imagesObject[props.banner]
          } alt="Image" />
        )
      }}
    />
  )
}

BannerImage.propTypes = {
  banner: PropTypes.string.isRequired,
}


export default class HomeBanner extends Component {

  render() {
    const carSelection = value => {
      if (value === "aura") {
        return (
          <>
            <BannerImage banner="auraBanner" />
          </>
        )
      }
      else if (value === "aura_cng") {
        return (
          <>
            <BannerImage banner="auraCngBanner" />
          </>
        )
      }
      else if (value === "grand_i10_nios_cng") {
        return (
          <>
            <BannerImage banner="niosCngBanner" />
          </>
        )
      }
      else if (value === "grand_i10_nios") {
        return (
          <>
            <BannerImage banner="niosBanner" />
          </>
        )
      }
      else if (value === "creta_electric") {
        return (
          <>
            <BannerImage banner="cretaelectricBanner" />
          </>
        )
      }
      else if (value === "creta") {
        return (
          <>
            <BannerImage banner="cretaBanner" />
          </>
        )
      }  else if (value === "creta_N_Line") {
        return (
          <>
            <BannerImage banner="cretaNLineBanner" />
          </>
        )
      }  else if (value === "i20") {
        return (
          <>
            <BannerImage banner="i20Banner" />
          </>
        )
      }
      // else if (value === "i20_iMT") {
      //   return (
      //     <>
      //       <BannerImage banner="iMTBanner" />
      //     </>
      //   )
      // }
      else if (value === "i20_N_Line") {
        return (
          <>
            <BannerImage banner="iNLineBanner" />
          </>
        )
      }
      // else if (value === "i20_N_Line_MT") {
      //   return (
      //     <>
      //       <BannerImage banner="iNLineiMTBanner" />
      //     </>
      //   )
      // }
      else if (value === "tucson") {
        return (
          <>
            <BannerImage banner="tucsonBanner" />
          </>
        )
      } else if (value === "venue") {
        return (
          <>
            <BannerImage banner="venueBanner" />
          </>
        )
      } else if (value === "venue_N_Line") {
        return (
          <>
            <BannerImage banner="venueNLineBanner" />
          </>
        )
      }
      // else if (value === "venue_iMT") {
      //   return (
      //     <>
      //       <BannerImage banner="venueiMTBanner" />
      //     </>
      //   )
      // }
      else if (value === "all_new_verna") {
        return (
          <>
            <BannerImage banner="allNewVernaBanner" />
          </>
        )
      } else if (value === "alcazar") {
        return (
          <>
            <BannerImage banner="alcazarBanner" />
          </>
        )
      } else if (value === "exter") {
        return (
          <>
            <BannerImage banner="exterBanner" />
          </>
        )
      }
      else if (value === "exter_cng") {
        return (
          <>
            <BannerImage banner="exterCngBanner" />
          </>
        )
      }

    }

    return (
      <div className="homebanner mb-4">
       {/* <div className="py-2 w-100 text-center desktopView cookieLine">
          We use cookies to ensure that we give you the best experience on the
          website
        </div>*/}
        <div
          id="banner"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              {carSelection(this.props.carName)}
            </div>
          </div>
        </div>

        {/* <hr className="desktopView bannerLine sr-only" /> */}
      </div>
    )
  }
}

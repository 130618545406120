import React from "react";

const AboutUsLandingPage = ({ pageContext = {}, ...props }) => {
  const { Dealer_Name } = pageContext;

  return (
    <main className="common-custom-block homebanner" role="main">
      <section className="main-body-section p-5">
        <p className="font-italic">
          <strong>{Dealer_Name}</strong> is an authorised dealership of HMIL.
          Hyundai Motor India Limited (HMIL) is a wholly owned subsidiary of
          Hyundai Motor Company (HMC). In line with HMC’s global brand vision
          of ‘Progress for Humanity,’ we adopt sustainable and green
          manufacturing operations, offering mobility solutions with
          industry-leading technology.
        </p>
        <p className="font-italic">
          HMIL operates with a network of 1,366 sales points and 1,550 service
          points across India. Our model line-up consists of car models across
          different customer segments, including Grand i10 NIOS, i20, i20 N
          Line, AURA, EXTER, VENUE, VENUE N Line, VERNA, CRETA, CRETA N Line,
          ALCAZAR, TUCSON, and the all-electric SUV IONIQ 5. HMIL’s
          manufacturing plant near Chennai is optimised to manufacture our full
          range of vehicle models. HMIL also forms a critical part of HMC’s
          global export hub, with exports spanning across Africa, the Middle
          East, and other countries including Bangladesh, Nepal, Bhutan, and
          Sri Lanka.
        </p>
      </section>
    </main>
  );
};

export default AboutUsLandingPage;

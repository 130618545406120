import React from "react";

const Section = ({ title, titleClass, children }) => (
  <section className={titleClass}>
    {title ? <h4>{title}</h4> : ""}
    {children}
  </section>
);

const PrivacyPolicyPage = () => {
  return (
    <main className="common-custom-block homebanner" role="main">
      <article className="main-body-section p-5">
        <Section title="Hyundai Legal Disclaimer Privacy Policy">
          <p>
            Hyundai Motor India Ltd (HMIL, “we”, “us” or “our”) respects and
            recognizes the privacy and security of the visitors to the Website
            and appreciate that your personal information is very important. Our
            goal is to provide you with a personalized Internet experience that
            delivers the information, resources and services that are most
            relevant and helpful to you. In order to achieve this goal, we may
            collect information during your visits to understand what
            differentiates you from each of our millions of other users. In
            order to demonstrate our commitment to your privacy, we have
            prepared this Privacy Policy.
          </p>
          <p>
            This Privacy Policy explains what personally identifiable
            information of yours is collected, sets out key information
            regarding our collection, use, process, disclosure and storage of
            your personal information, how and when we might use your
            information, how we protect your information, who has access to your
            information, and of your ability to access and correct it. In order
            to fully understand your rights, we encourage you to read this
            Privacy Policy. HMIL reserves the right at any time and without
            notice to change this Privacy Policy simply by posting such change
            on the Website. Any such change will be effective immediately upon
            posting. Continued usage of the website following changes to these
            terms will mean that you accept these changes. Please check our
            Privacy Policy regularly for updates.
          </p>
          <p>
            The Website contains links to other sites; HMIL is not responsible
            for the privacy practices or the content of such websites, which are
            not under the control of HMIL.
          </p>
        </Section>
        <Section title="What personally identifiable information does HMIL gather/track about you through its website?">
          <p>
            HMIL may collect or require you to furnish information when you
            choose the Products, Services, Promotions, Offers, etc. available in
            this Website. HMIL may collect personally identifiable information
            viz., your name, e-mail, address, date of birth, gender, profession,
            marital status, contact numbers, vehicle information (model, model
            variant, registration number, VIN number, date of purchase,
            ownership type, state of registration, current city, insurance
            duration, mileage covered), etc. and unique identifiers (like your
            password). You hereby expressly consent HMIL to store, retain or
            transfer the personal data (i.e., personally identifiable
            Information) that voluntarily supplied herein and process and use it
            by HMIL or its affiliates, associates, Dealers, Agencies, etc. to
            fulfil your requests for products, services, offers, etc. and to
            contact you through outbound call by Telephone/ Mobile Numbers or
            send SMS or Email about offers, marketing and/or promotions, product
            related information, newsletter, market survey, poll, research,
            study, programs, enquiries about offerings, services, service
            reminders and other legitimate purposes. Your account information is
            password-protected and HMIL has taken adequate security measures to
            secure access to your personal data.
          </p>
        </Section>
        <Section title="Cookies">
          <p>
            During normal website usage, we may also collect certain information
            about yourself, such as your Internet Protocol (IP) address, the
            time of your visit, pages you request, name of the internet service
            provider, the website or media that referred you to us etc. through
            the use of cookies which are small files stored on your web browser
            in order to optimize the design of the Site. Cookies enable us to
            track and aggregate user behavior data so that we may better target
            the interests of our users and enhance their experience on the Site.
            You can set your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, some website features or services may
            not function properly without cookies.
          </p>
        </Section>
        <Section title="Web Beacons">
          <p>
            Some of our web pages, commercial email messages and/or newsletters
            may contain electronic images known as web beacons, which are also
            called single-pixel GIFs. Web beacons collect information including
            a cookie number, time and date of a page view and a description of
            the page on which the web beacon resides. Web beacons are a
            technique we may use to enhance and personalize the Site and the
            products and Services we offer, to provide product information and
            advertisements that are more relevant to your interests, compile
            aggregated statistics about the usage of the Site and to track the
            number of users who have opened and acted upon our commercial email
            messages.
          </p>
        </Section>
        <Section title="What does HMIL do with the information?">
          <p>
            We use the data gathered to send you information about Product,
            Service, research, campaign, etc. Your contact information is also
            used to contact you when necessary. HMIL records this basic
            information about visits to the Website, for systems administration,
            statistical and troubleshooting purposes. We use your IP address to
            help diagnose problems with our server and to administer our
            website. We use your personal data to tailor your experience at our
            site showing you content that we think you might be interested in
            and displaying the content according to your preferences.
          </p>
        </Section>
        <Section title="How HMIL protects your information?">
          <p>
            The privacy and protection of your personally identifiable
            information is vitally important to us. All personal data
            accumulated will be acquired, processed, and used according to the
            applicable regulations governing the protection of personal data for
            the sole purpose of managing and maintaining HMIL’s own legitimate
            business interests. Any user statistics that we may provide to
            prospective advertisers or partners regarding your Website usage are
            provided in the aggregate only and do not include any personally
            identifiable information about any individual user. Despite our
            efforts to protect your personal identifiable information through
            security procedures commensurate with industry standards, we cannot
            assure that Personal Data that you provide will never be disclosed
            in a manner that is inconsistent with the Policy or ensure or
            warrant the security of any information you transmit to us. You
            transmit all such information at your own risk.
          </p>
        </Section>
        <Section title="With whom does HMIL share the information?">
          <p>
            HMIL does not sell or rent your personally identifiable information
            to any third parties. Information, including Personal Information,
            location, your Device identifiers etc. about you supplied to us may
            be shared with third parties viz., affiliates, group companies,
            auditors, authorized dealers, legal advisers and marketing partners
            etc. contracted to provide services on our behalf. HMIL as it deems
            appropriate may use your contact information for the use of any
            affiliate, dealer or third party to provide these services. HMIL may
            disclose information if required to do so by any law enforcing
            agency. We may also transfer your personally identifiable
            information to any of our Affiliate or group company outside India.
          </p>
        </Section>
        <Section title="Third-party websites">
          <p>
            The Website may contain links to Applications/websites owned or
            operated by third parties, including owned or operated by third
            party service providers affiliated with HMIL. We take no
            responsibility for the privacy practices or content of other
            Applications/websites. Other Applications/websites may contain their
            own privacy policies and their owners or operators are responsible
            for informing you about their security and privacy practices. We
            recommend that you thoroughly read the privacy policies of these
            third party applications/websites.
          </p>
        </Section>
        <Section titleClass="mb-3">
          HMIL may change this Privacy Policy from time to time. If we make any
          substantial changes, we will notify you by posting a prominent
          announcement on our website.
          <p className="mt-3">
            <strong>Corrections Upon request</strong>, HMIL will correct
            personal information that you might have disclosed and state that it
            is erroneous. To request a correction send an e-mail to&nbsp;
            <a href="mailto:crsales@hmil.net">crsales@hmil.net</a> or letter in
            writing at the following address:
          </p>
          <address>
            Hyundai Motor India Limited
            <br />
            Location: Gurugram
            <br />
            Address: Plot C-11, City Center, Sector – 29, Gurugram, Haryana,
            122001
            <br />
            Telephone: 0124-6962000
          </address>
        </Section>
      </article>
    </main>
  );
};

export default PrivacyPolicyPage;

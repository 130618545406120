import React, { Component, createRef } from "react";
import { Helmet } from "react-helmet";
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMapMarkerAlt,
  faCarAlt,
} from "@fortawesome/free-solid-svg-icons";

import "../css/footer.css";
import "../css/globalRules.css";
// import GoogleMapComponent from "./googleMap";
import deviceDetection from "./deviceDetection"
import fblike from "../images/fblike.png";
// import { _osTrackingEvent } from "./templates/_osTrackingEvent";

export class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowCookieNote: true,
      isRequestSlide: false,
    };
    this.bookingFormModalRef = createRef();
    this.timeoutIntervalRef = createRef();
    this.modalComponentRef = createRef();
  }

  closeModal = () => {
    if (this.modalComponentRef.current) {
      this.modalComponentRef.current.hide();
      if (window && window.gtag) {
        window.gtag("event", "form_popup_closed", {
          event_category: "form",
          event_label: "Form popup closed",
          car: this.props.carDisplayName
        });
      }
    }
  };

  dismissCookieNote = () => {
    this.setState({
      isShowCookieNote: false
    });
  }

  dismissRequestSlide = () => {
    this.setState({
      isRequestSlide: false
    });
  }

  getFooterDOM(containerClassName){
    if(!this.state.isShowCookieNote){
      return null;
    }

    return (
      <div className={`py-2 w-100 text-center ${containerClassName} cookieLine`}>
        <span className="cookieText">We use cookies to ensure that we give you the best experience on the
        website</span>
        <span className="fa fa-close pull-right" onClick={this.dismissCookieNote}/>
      </div>
    );
  }

  componentDidMount () {
    const myModal = this.bookingFormModalRef.current;
    if (myModal) {
      const modal = new window.bootstrap.Modal(myModal);
      this.modalComponentRef.current = modal;
      this.timeoutIntervalRef.current = setTimeout(() => {
        modal.show();
      }, 2000);
    } else {
      this.timeoutIntervalRef.current = setTimeout(() => {
        this.setState({
          isRequestSlide: true
        });
      }, 2000);
    }
  }

  componentWillUnmount () {
    if (this.timeoutIntervalRef.current) {
      window.clearInterval(this.timeoutIntervalRef.current);
    }
  }

  onFooterClick = function() {
    if (typeof window === "undefined") { return null };
    window._footer_tracker();
  }

  renderPrivateLink = () => {
    return (
      <div className="private-link-block">
        <a
          className="btn btn-aboutus anchor-block"
          href="/aboutus"
          target="_blank"
        >
          About us
        </a>
        <span>|</span>
        <a
          className="btn btn-privacypolicy anchor-block"
          href="/privacypolicy"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
    )
  };

  render() {
    const { isNormalVisit } = this.props;
    return (
      <div>
        {/* <Helmet>
          <div id="fb-root"></div>
          <script
            async
            defer
            crossorigin="anonymous"
            src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v10.0"
            nonce="Sbh12yun"
          ></script>
        </Helmet> */}
        <footer className=" desktopView desktopFooter">
          {this.getFooterDOM()}
          <div className="container-fluid">
            <div className="row mx-4-md my-5-md px-3 py-5">
              <div className="col-md-4">
                <h4>Showroom Address</h4>
                <br />
                <div className="col-md-8">
                  <p className="text-wrap">{this.props.address}</p>
                </div>
              </div>

              <div className="col-md-4">
                <h4>Business Hours</h4>
                <br />
                <p>10:00 AM - 07:00 PM</p>
              </div>

              <div className="col-md-4">
                <h4>Reviews</h4>{/*Google */}
                <br />

                <div>
                  {
                    !this.props.isMobile ? (
                      <a href={this.props.fbLink}>
                        <img src={fblike} width="25%" height="25%" />
                      </a>
                    ) : null
                  }
                  <br />
                  {/* <div
                class="fb-like mt-3"
                data-href={this.props.fbLink}
                data-width=""
                data-layout="button"
                data-action="like"
                data-size="small"
                data-share="true"
              ></div> */}
                </div>
              </div>
            </div>
            {this.renderPrivateLink()}
          </div>
          <div className={`request-body ${this.state.isRequestSlide ? "active" : ""}`}>
            {this.props.bookingFormDom}
            <div className="submitclosebtn" onClick={this.dismissRequestSlide}>
              <a className="submitCross" href="javascript:void(0);">
                <img src="https://res.cloudinary.com/onlinesales/image/upload/v1646142183/product/close-icon.png" alt="close icon" title="close icon" />
              </a>
            </div>
          </div>
        </footer>
        <footer className="mobileView mobFooter">
          {this.getFooterDOM()}
          {this.renderPrivateLink()}
        </footer>
        <footer className="mobileView mobFooter">
          <div className="container-fluid">
            <div className="row mx-1 my-1 px-1 py-1 text-center">
              <div className={`col-${isNormalVisit ? "4" : "6"} location`}>
                {/* <button
                  key = "#mapModal"
                  type="button"
                  class="mobFooterBtn btn faFooter"
                  data-bs-toggle="modal"
                  data-bs-target="#mapModal"
                  onClick = {() => _osTrackingEvent({
                    leadStep:"onMapClick",
                    cust_phone:this.props.contact,
                    latitude:this.props.lat,
                    longitude:this.props.lng
                  })}
                > */}
                <button
                  className="mobFooterBtn btn faFooter"
                  id="btn-direction"
                  type="button"
                  key = "#map"
                  onClick={()=> window.open(this.props.gmbLink, "_blank")}
                >

                  <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
                </button>
              </div>

              <div className={`col-${isNormalVisit ? "4" : "6"} fillform`}>
                <AnchorLink to="/#bookDrive">
                  <button className="mobFooterBtn btn faFooter">
                    <FontAwesomeIcon icon={faCarAlt}></FontAwesomeIcon>
                  </button>
                </AnchorLink>
              </div>
              {isNormalVisit ?
                <div className="col-4 contact">
                  <span className="mobFooterBtn btn"
                  onClick={this.onFooterClick}>
                    <a className="fa fa-phone faFooter" href={"tel:" + `${this.props.contact}`}></a>
                  </span>
                </div>
              :null}

            </div>
          </div>
        </footer>
        {this.props.isBookingModelVisible ? (
          <div
            ref={this.bookingFormModalRef}
            className="modal fade requestmodal"
            tabindex="-1"
            aria-labelledby="bookingFormModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="closemodal" onClick={this.closeModal}>
                    <a className="submitCross" href="javascript:void(0);">
                      <img src="https://res.cloudinary.com/onlinesales/image/upload/v1646142183/product/close-icon.png" alt="close icon" title="close icon" />
                    </a>
                  </div>
                  {this.props.bookingFormDom({closeModal: this.closeModal})}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* <div
          className="modal fade"
          id="mapModal"
          tabindex="-1"
          aria-labelledby="mapModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <GoogleMapComponent lat={this.props.lat} lng={this.props.lng} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default deviceDetection(Footer, "(max-width: 1024px)");

import React, { Component } from "react";
import "../css/bookingForms.css";
import BookingTestDrive from "./bookingTestDrive";

export default class BookingForms extends Component {
  render() {
    return (
      <div>
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="test-drive"
              data-bs-toggle="pill"
              data-bs-target="#test-drive-tab"
              type="button"
              role="tab"
              aria-controls="test-drive-tab"
              aria-selected="true"
            >
              Request a Test Drive
            </button>
          </li>
        </ul>
        <hr className="hr-line" />
        <div className="tab-content mt-5" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="test-drive-tab"
            role="tabpanel"
            aria-labelledby="test-drive"
          >
            <BookingTestDrive
              gridSize={this.props.formGridSize}
              city={this.props.city}
              state={this.props.state}
              name={this.props.name}
              ClientId={this.props.ClientId}
              PixelID={this.props.PixelID}
              carDisplayName={this.props.carDisplayName}
              formId={this.props.formBookingId}
              isNormalVisit={this.props.isNormalVisit}
              defaultConfig={this.props.defaultConfig}
            />
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types";

import "../css/carCarousel.css"
import "../css/globalRules.css"
import chevron from "../images/right.svg"

const carDisplayNames = {
  grand_i10_nios: "Grand i10 NIOS",
  grand_i10_nios_cng: "Grand i10 NIOS CNG",
  i20: "i20",
  // i20_iMT: "i20 iMT",
  i20_N_Line: "i20 N Line",
  // i20_N_Line_MT: "i20 N Line MT",
  aura: "Aura",
  aura_cng: "Aura CNG",
  all_new_verna: "All New Verna",
  creta: "Creta",
  creta_N_Line: "Creta N Line",
  venue: "Venue",
  venue_N_Line: "Venue N Line",
  // venue_iMT: "Venue iMT",
  tucson: "Tucson",
  // kona: "Kona",
  alcazar: "Alcazar",
  exter: "EXTER",
  exter_cng: "EXTER CNG",
  creta_electric: "Creta Electric",
};

const SliderImages = (props) => {
  return (
    <StaticQuery query={graphql`
      query {

        aura: file(relativePath: { eq: "slider/aura.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        auraCng: file(relativePath: { eq: "slider/aura.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        nios: file(relativePath: { eq: "slider/nios.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        niosCng: file(relativePath: { eq: "slider/nios.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        i20: file(relativePath: { eq: "slider/i20.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        i20iMT: file(relativePath: { eq: "slider/i20.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        iMT: file(relativePath: { eq: "slider/I20-n-line-mt.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        i20NLine: file(relativePath: { eq: "slider/I20-n-line.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        cretaelectric: file(relativePath: { eq: "slider/creta-ev.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        creta: file(relativePath: { eq: "slider/creta.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        cretaNLine: file(relativePath: { eq: "slider/creta-n-line.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        tucson: file(relativePath: { eq: "slider/tucson.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        allNewVerna: file(relativePath: { eq: "slider/new-verna-slider.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        alcazar: file(relativePath: { eq: "slider/alcazar.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        exter: file(relativePath: { eq: "slider/exter.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        exterCng: file(relativePath: { eq: "slider/exter.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        venue: file(relativePath: { eq: "slider/venue.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        venueNLine: file(relativePath: { eq: "slider/venue-n-line.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        venueiMT: file(relativePath: { eq: "slider/venue-imt.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }`}

      render={data => {
        const imageObject = {
          "aura": [{ ...data.aura.childImageSharp.fluid }],
          "aura_cng": [{ ...data.auraCng.childImageSharp.fluid }],
          "i20": [{ ...data.i20.childImageSharp.fluid }],
          "creta_electric": [{ ...data.cretaelectric.childImageSharp.fluid }],
          "creta": [{ ...data.creta.childImageSharp.fluid }],
          "creta_N_Line": [{ ...data.cretaNLine.childImageSharp.fluid }],
          "grand_i10_nios": [{ ...data.nios.childImageSharp.fluid }],
          "grand_i10_nios_cng": [{ ...data.niosCng.childImageSharp.fluid }],
          "tucson": [{ ...data.tucson.childImageSharp.fluid }],
          "all_new_verna": [{ ...data.allNewVerna.childImageSharp.fluid }],
          "venue": [{ ...data.venue.childImageSharp.fluid }],
          "alcazar":[{ ...data.alcazar.childImageSharp.fluid }],
          "exter":[{ ...data.exter.childImageSharp.fluid }],
          "exter_cng": [{ ...data.exterCng.childImageSharp.fluid }],
          "i20_iMT":[{ ...data.i20iMT.childImageSharp.fluid }],
          "i20_N_Line":[{ ...data.i20NLine.childImageSharp.fluid }],
          "i20_N_Line_MT":[{ ...data.iMT.childImageSharp.fluid }],
          "venue_N_Line":[{ ...data.venueNLine.childImageSharp.fluid }],
          "venue_iMT":[{ ...data.venueiMT.childImageSharp.fluid }],

        }
        return (
          <Img fluid={imageObject[props.slideImage]}
            className={props.classNames} alt="Image" />
        )
      }}
    />

  )

}


SliderImages.propTypes = {
  slideImage: PropTypes.string.isRequired,
}


export default class CarCarousel extends Component {
  render() {
    var settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "5%",
      slidesToShow: 3,
      speed: 400,
      focusOnSelect: true,
      arrows: true,
      initialSlide: this.props.carIndex,
      ease: true,
      nextArrow: (
        <div>
          <div className="next-slick-arrow"> <img src={chevron} alt="nextArrow" />
          </div>
        </div>
      ),
      prevArrow: (
        <div>
          <div className="prev-slick-arrow"> <img src={chevron}
            alt="rightArrow" className="rotateimg180" /> </div>
        </div>
      ),

      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
      ],
    }

    return (
      <div>
        <h1 className="text-center mb-5">
          Models
        </h1>
        <div className="mb-5">
          {/* settings.initialSlide = {this.props.carIndex} */}
          <Slider {...settings}>
            {
              this.props.carList?.map((carKey) => {
                return (
                  <div>
                    <Link to={`/${carKey}`}>
                      <SliderImages slideImage={`${carKey}`} classNames={"carousel - item"} />
                    </Link>
                    <br />
                    <p className="text-center">{this.props.carDisplayNames?.[carKey]}</p>
                  </div>
                );
              })
            }
          </Slider>
        </div>
      </div>
    )
  }
}
